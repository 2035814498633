import * as d3 from "d3";
import "./tasting_zone_component.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static values = {
    drinkFrom: Number,
    drinkTo: Number,
    heydayFrom: Number,
    heydayTo: Number,
    vintageYear: Number,
  };

  margin = { top: 0, right: 15, bottom: 0, left: 15 };

  connect() {
    this.setupDatesAndScale();
    this.setupSvg();
    this.resize();
  }

  setupDatesAndScale() {
    this.greenZoneStartDate = new Date(this.drinkFromValue, 0, 1);
    this.greenZoneEndDate = new Date(this.drinkToValue, 0, 1);
    this.xScale = d3
      .scaleTime()
      .domain([new Date(this.vintageYear, 0, 1), this.greenZoneEndDate]);
  }

  setupSvg() {
    this.svg = d3
      .select(".c-tasting-zone-timeline")
      .append("svg")
      .attr("width", "100%")
      .attr("height", "25");
  }

  resize() {
    const parentWidth = d3
      .select(".c-tasting-zone-timeline")
      .node()
      .getBoundingClientRect().width;
    this.width = parentWidth - this.margin.left - this.margin.right;
    this.xScale.range([this.margin.left, this.width - this.margin.right]);
    this.svg.attr("width", parentWidth);
    this.updateAll();
  }

  updateAll() {
    this.updateAxis();
    this.updateThumbAndThumbBg();
    this.updateTastingZonePeriod();
    this.updateHashZone();
    this.updateHashZoneTitle();
  }

  updateAxis() {
    let tickValues = [
      new Date(this.vintageYear, 0, 1),
      this.greenZoneStartDate,
      this.greenZoneEndDate,
      new Date(this.heydayFromValue, 0, 1),
      new Date(this.heydayToValue, 0, 1),
    ];
    this.svg.call(d3.axisBottom(this.xScale).tickValues(tickValues));
  }

  updateThumbAndThumbBg() {
    let currentDate = new Date();
    let xPosition = this.xScale(currentDate);
    this.updateThumb(".c-tasting-zone-thumb", xPosition, currentDate);
    this.updateThumb(".c-tasting-zone-thumb-bg", xPosition);
  }

  updateThumb(id, xPosition) {
    let currentDate = new Date();
    let thumb = d3.select(id);
    thumb.attr("cx", xPosition);
    if (this.isInGreenZone(currentDate)) {
      thumb.attr("stroke", "#59CB00");
    } else {
      thumb.attr("stroke", "#aba7af");
    }
  }

  isInGreenZone(date) {
    return date >= this.greenZoneStartDate && date <= this.greenZoneEndDate;
  }

  updateTastingZonePeriod() {
    this.updateTastingZone(
      ".c-tasting-zone-period",
      this.greenZoneStartDate,
      this.greenZoneEndDate
    );
  }

  updateTastingZone(id, startDate, endDate) {
    let zone = d3.select(id);
    zone
      .attr("x", this.xScale(startDate))
      .attr("width", this.xScale(endDate) - this.xScale(startDate));
  }

  updateHashZone() {
    let hashZoneStartDate = new Date(this.heydayFromValue, 0, 1);
    let hashZoneEndDate = new Date(this.heydayToValue, 0, 1);
    this.updateTastingZone(
      ".c-tasting-zone-hash-zone-hashed",
      hashZoneStartDate,
      hashZoneEndDate
    );
    this.updateLine(".c-tasting-zone-hash-zone-start-line", hashZoneStartDate);
    this.updateLine(".c-tasting-zone-hash-zone-end-line", hashZoneEndDate);
  }

  updateLine(id, date) {
    let line = d3.select(id);
    let xPosition = this.xScale(date);
    line.attr("x1", xPosition).attr("x2", xPosition);
  }

  updateHashZoneTitle() {
    let hashZone = d3.select(".c-tasting-zone-hash-zone-hashed");
    let hashZoneTitle = d3.select(".c-tasting-zone-hash-zone-title");
    let hashZoneBBox = hashZone.node().getBBox();
    let titleBBox = hashZoneTitle.node().getBBox();
    let hashZoneCenterX = hashZoneBBox.x + hashZoneBBox.width / 2;
    let titleX = hashZoneCenterX - titleBBox.width / 2;
    hashZoneTitle.attr("transform", `translate(${titleX - titleBBox.x}, 0)`);
  }

  get vintageYear() {
    if (this.vintageYearValue == "0") {
      return this.drinkFromValue - 2;
    }

    return this.vintageYearValue;
  }
}
