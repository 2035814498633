import { menu, refinementList } from "instantsearch.js/es/widgets";

const refinementListDefaultOptions = {
  cssClasses: {
    list: "c-admin--search--filters-items",
    item: "c-admin--search--filters-item",
    label: "c-admin--search--filters-label",
    count: "c-admin--search--filters-count",
    showMore: "c-admin--search--filters-more",
  },
  showMore: true,
  templates: {
    showMoreText(data, { html }) {
      return html`<span
        >${data.isShowingMore ? "Voir moins" : "Voir plus"}</span
      >`;
    },
  },
};

export default {
  complete: {
    widget: menu,
    title: "Statut de la fiche",
    options: {
      cssClasses: {
        list: "c-admin--search--filters-items",
        item: "c-admin--search--filters-item",
        selectedItem: "c-admin--search--filters-selected",
        link: "c-admin--search--filters-link",
        count: "c-admin--search--filters-count",
      },
      attribute: "human_complete",
      sortBy: ["name"],
    },
  },
  color: {
    widget: refinementList,
    title: "Couleur",
    options: {
      ...refinementListDefaultOptions,
      attribute: "color",
    },
  },
  certifications: {
    widget: refinementList,
    title: "Certification",
    options: {
      ...refinementListDefaultOptions,
      attribute: "certifications",
    },
  },
  classification: {
    widget: refinementList,
    title: "Classification",
    options: {
      ...refinementListDefaultOptions,
      attribute: "classification",
    },
  },
  country: {
    widget: refinementList,
    title: "Pays",
    options: {
      ...refinementListDefaultOptions,
      attribute: "country",
    },
  },
  designation: {
    widget: refinementList,
    title: "Désignation",
    options: {
      ...refinementListDefaultOptions,
      attribute: "designation",
    },
  },
  sub_region: {
    widget: refinementList,
    title: "Sous-région",
    options: {
      ...refinementListDefaultOptions,
      attribute: "sub_region",
    },
  },
  sub_type: {
    widget: refinementList,
    title: "Sous-type",
    options: {
      ...refinementListDefaultOptions,
      attribute: "sub_type",
    },
  },
  type: {
    widget: refinementList,
    title: "Type",
    options: {
      ...refinementListDefaultOptions,
      attribute: "type",
    },
  },
  region: {
    widget: refinementList,
    title: "Région",
    options: {
      ...refinementListDefaultOptions,
      attribute: "region",
    },
  },
};
