import "./dropzone_component.css";

import Dropzone from "dropzone";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static outlets = ["submittable"];
  static targets = [
    "dropzone",
    "hiddenInput",
    "dropzoneHiddenInput",
    "validInput",
  ];
  static values = {
    acceptedFiles: String,
    uploadUrl: String,
  };

  connect() {
    this.initDropzone();
  }

  disconnect() {
    this.dropzone.destroy();
    this.dropzone = null;
  }

  initDropzone() {
    this.dropzone = new Dropzone(this.dropzoneTarget, this.dropzoneConfig);

    this.dropzone.on("success", (file) => this.fileUploaded(file));
    this.dropzone.on("removedfile", (file) => this.fileRemoved(file));

    this.showExistingFile();
  }

  refresh() {
    this.showExistingFile();
  }

  fileUploaded(file) {
    const data = JSON.parse(file.xhr.response).data;
    this.hiddenInputTarget.value = JSON.stringify(data);
    this.hasSubmittableOutlet && this.submittableOutlet.submit();
  }

  fileRemoved() {
    this.dropzone.removeAllFiles(true);
    this.hiddenInputTarget.value = "";
    this.validInputTarget.value = "";
    this.hasSubmittableOutlet && this.submittableOutlet.submit();
  }

  showExistingFile() {
    if (!this.hiddenInputTarget.value) return;

    const metadata = JSON.parse(this.hiddenInputTarget.value).metadata;
    let mockFile = { name: metadata.filename, size: metadata.size };
    this.dropzone.displayExistingFile(mockFile, "");
  }

  icon(key) {
    return `<svg class="c-icon-root c-icon-${key}" role="img"><use xlink:href="#c-icon-${key}"></use></svg>`;
  }

  get csrfToken() {
    return document.querySelector("meta[name=csrf-token]").content;
  }

  get dropzoneConfig() {
    return {
      url: this.uploadUrlValue,
      clickable: true,
      paramName: "file",
      addRemoveLinks: true,
      acceptedFiles: this.acceptedFilesValue,
      maxFiles: 1,
      createImageThumbnails: false,
      hiddenInputContainer: this.dropzoneHiddenInputTarget,
      headers: {
        "X-CSRF-Token": this.csrfToken,
      },
      dictCancelUpload: this.icon("reset"),
      dictRemoveFile: this.icon("reset"),
      previewTemplate: `
      <div class="dz-preview dz-file-preview">
        <div class="dz-details">
          ${this.icon("paper")}
          <div class="dz-filename"><span data-dz-name></span></div>
        </div>
        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
        <div class="dz-error-message"><span data-dz-errormessage></span></div>
      </div>
      `,
    };
  }
}
