import "./identity_page_component.css";
import { Turbo } from "@hotwired/turbo-rails";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  changeVintage(e) {
    const vintage = e.target.value;
    Turbo.visit(vintage);
  }
}
