import "./payment_setup_component.css";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["message", "payment"];
  static values = {
    stripePublicApiKey: String,
    lockwineAddressName: String,
    returnUrlValue: String,
    clientSecret: String,
    defaultValues: Object,
    formFieldId: String,
  };

  async connect() {
    await this.loadStripe();
    this.initStripeElements();
    this.mountPaymentElement();
    this.messageTarget.innerText = "";
  }

  disconnect() {
    if (this.paymentElement) {
      this.paymentElement.destroy();
      this.paymentElement = null;
    }
  }

  async loadStripe() {
    if (this.stripe) return;

    loadStripe.setLoadParameters({ advancedFraudSignals: false });
    this.stripe = await loadStripe(this.stripePublicApiKeyValue);
  }

  initStripeElements() {
    if (this.elements) return;

    this.elements = this.stripe.elements({
      clientSecret: this.clientSecretValue,
      appearance: this.appearance,
    });
  }

  mountPaymentElement() {
    this.paymentElement = this.elements.create("payment", this.options);
    this.paymentElement.mount(this.paymentTarget);
  }

  submit(e) {
    e.preventDefault();

    this.messageTarget.innerText = "Chargement...";

    this.stripe
      .confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: this.returnUrlValue,
        },
        redirect: "if_required",
      })
      .then(
        function (result) {
          if (result.error) {
            this.messageTarget.innerText = result.error.message;
          } else {
            this.messageTarget.innerText = "";
            this.formField.value = result.setupIntent.payment_method;
            this.formField.form.submit();
          }
        }.bind(this)
      );
  }

  get appearance() {
    return {
      theme: "flat",
    };
  }

  get options() {
    return {
      defaultValues: {
        billingDetails: this.defaultValuesValue,
      },
      business: { name: this.lockwineAddressNameValue },
    };
  }

  get formField() {
    return document.getElementById(this.formFieldIdValue);
  }
}
