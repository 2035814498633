export default {
  showLoadingIndicator: false,
  cssClasses: {
    list: "c-list-cards-root c-list-cards-format-vertical",
    item: "c-card-root c-card-format-horizontal",
    loadMore: "c-button-root c-button-size-tiny c-button-position-center",
  },
  templates: {
    empty(results, { html }) {
      return html`<div class="c-admin--list-root">
        Aucun résultat pour <q>${results.query}</q>
      </div>`;
    },
    item(hit, { html, components }) {
      const url = new URL(
        hit.website_url.replace(":customer_id", hit.customer_code)
      );
      // link to path only, without domain
      const href = url.pathname + url.search;
      const packshot =
        hit.packshots?.horizontal?.medium ||
        "/fallbacks/packshot-horizontal-medium.png";
      let vintages = [hit.vintages.at(-1)];
      if (hit.vintages.length > 1) vintages.push(hit.vintages.at(0));
      return html`
        <div class="c-card-image-wrapper">
          ${packshot
            ? html`<img
                src="${packshot}"
                loading="lazy"
                class="c-card-image"
              />`
            : html`<svg class="c-icon-root c-icon-trek--pic" role="img">
                <use xlink:href="#c-icon-trek--pic"></use>
              </svg>`}
        </div>

        <div class="c-card-content">
          <div class="c-card-title">
            <a href="${href}" class="c-card-link"
              >${components.Highlight({ hit, attribute: "display_name" })}
            </a>
          </div>
          <div class="c-card-info">
            <span class="c-card-year">${vintages.join(" → ")}</span>
          </div>
        </div>
        <svg class="c-icon-root c-icon-long-arrow-right c-card-icon" role="img">
          <use xlink:href="#c-icon-long-arrow-right"></use>
        </svg>
      `;
    },
    showMoreText(data, { html }) {
      return html`<span class="c-button-text">Voir plus de résultats</span>`;
    },
  },
};
