import "./customer_switch_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["form", "input", "results"];

  reset(e) {
    e.preventDefault();
    this.formTarget.reset();
    this.formTarget.requestSubmit();
    this.inputTarget.focus();
  }

  enterFirst(e) {
    e.preventDefault();
    if (this.hasResultsTarget) {
      this.resultsTarget.querySelector("a").click();
    }
  }
}
