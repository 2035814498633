import "./password_field_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["input"];

  toggle() {
    this.inputTarget.type =
      this.inputTarget.type === "password" ? "text" : "password";
  }
}
