import "./graph_chart_component.css";
import { Controller as BaseController } from "@hotwired/stimulus";
import * as d3 from "d3";

export class Controller extends BaseController {
  static values = {
    data: Array,
  };

  connect() {
    this.drawChart();
  }

  disconnect() {
    this.clearChart();
  }

  drawChart() {
    const data = this.dataValue;
    const yBarScale = this.createYBarScale(data.map((d) => d.bar));
    const maxBarHeight = d3.max(data.map((d) => yBarScale(d.bar)));
    const yLineScale = this.createYLineScale(
      data.map((d) => d.line),
      maxBarHeight
    );
    const xScale = this.createXScale(data.length);
    const chart = this.selectChartElement();

    this.createBars(chart, data, yBarScale);
    this.addLabels(chart, data);
    this.drawLine(
      chart,
      data.map((d) => d.line),
      xScale,
      yLineScale
    );
  }

  redrawChart() {
    this.clearChart();
    this.drawChart();
  }

  clearChart() {
    d3.select(this.element)
      .selectAll("div.c-graph-chart-container, svg")
      .remove();
  }

  createYBarScale(data) {
    return d3
      .scaleLinear()
      .domain([0, d3.max(data)])
      .range([420, 0]);
  }

  createYLineScale(lineData) {
    const min = d3.min(lineData) * 0.98;
    const max = d3.max(lineData) * 1.02;
    return d3.scaleLog().domain([min, max]).range([420, 0]);
  }

  createXScale(length) {
    const barWidth = this.element.clientWidth / length;
    return d3
      .scaleLinear()
      .domain([0, length - 1])
      .range([barWidth / 2, this.element.clientWidth - barWidth / 2]);
  }

  selectChartElement() {
    const container = d3
      .select(this.element)
      .append("div")
      .classed("c-graph-chart-container", true);

    return container;
  }

  createBars(chart, data, yScale) {
    const barWidth = this.element.clientWidth / data.length - 2;

    chart
      .selectAll("div")
      .data(data)
      .join("div")
      .style("height", (d) => `${420 - yScale(d.bar)}px`)
      .style("width", `${barWidth}px`)
      .style("margin-right", (d, i) => (i === data.length - 1 ? "0" : "15px"))
      .classed("c-graph-chart-bar", true)
      .classed("c-graph-chart-label", true);
  }

  addLabels(chart) {
    chart
      .selectAll(".c-graph-chart-bar")
      .append("span")
      .text((d) => d.label)
      .classed("c-graph-chart-value", true);
  }

  drawLine(chart, lineData, xScale, yLineScale) {
    const svg = chart
      .append("svg")
      .attr("width", this.element.clientWidth)
      .classed("c-graph-chart-line", true);

    const line = d3
      .line()
      .x((d, i) => xScale(i))
      .y((d) => yLineScale(d))
      .curve(d3.curveMonotoneX);

    svg
      .append("path")
      .datum(lineData)
      .attr("fill", "none")
      .attr("stroke", "#800020")
      .attr("stroke-width", 8)
      .attr("d", line)
      .attr("stroke-linecap", "round");
  }
}
