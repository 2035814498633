import { Controller as BaseController } from "@hotwired/stimulus";
import * as d3 from "d3";

export class Controller extends BaseController {
  static values = {
    data: Array,
  };

  connect() {
    this.drawChart();
  }

  disconnect() {
    d3.select(this.element).select("svg").remove();
  }

  drawChart() {
    this.setLayout();
    this.setXScale();
    this.setYScale();
    this.lineGenerator();
    this.createSvg();
    this.createAxis();
    this.createGrid();
    this.createLabels();
    this.drawLastData();
  }

  setLayout() {
    this.width = 450;
    this.height = 250;
    this.marginTop = 20;
    this.marginRight = 20;
    this.marginBottom = 20;
    this.marginLeft = 20;
  }

  setXScale() {
    const padding = 50;
    this.x = d3
      .scaleUtc()
      .domain(d3.extent(this.dataValue, (d) => d.date))
      .range([this.marginLeft + padding, this.width - this.marginRight]);
  }

  setYScale() {
    const minValue = d3.min(this.dataValue, (d) => d.quantity);
    const maxValue = d3.max(this.dataValue, (d) => d.quantity);
    const padding = 5;

    this.y = d3
      .scaleLinear()
      .domain([minValue - padding, maxValue + padding])
      .range([this.height - this.marginBottom, this.marginTop]);
  }

  lineGenerator() {
    this.line = d3
      .line()
      .defined((d) => !isNaN(d.quantity))
      .x((d) => this.x(d.date))
      .y((d) => this.y(d.quantity))
      .curve(d3.curveMonotoneX);
  }

  createSvg() {
    this.svg = d3
      .select(this.element)
      .append("svg")
      .attr("viewBox", [0, 0, this.width, this.height]);
  }

  createAxis() {
    const yAxis = d3.axisLeft(this.y).ticks(this.height / 100);
    const tickValues = yAxis.scale().ticks(yAxis.ticks()[0]);

    this.svg
      .append("g")
      .attr("transform", `translate(${this.marginLeft},-10)`)
      .call(
        yAxis.tickValues(tickValues.filter((d, i) => i % 2 === 0)).tickSize(0)
      )
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .append("g")
          .selectAll("line")
          .data(tickValues.filter((d, i) => i % 2 !== 0))
          .join("line")
          .attr("stroke", "#F5F3F7")
          .attr("y1", (d) => this.y(d))
          .attr("y2", (d) => this.y(d))
          .attr("x2", this.width - this.marginLeft - this.marginRight)
      )
      .call((g) => g.append("text").attr("x", -this.marginLeft).attr("y", 10))
      .selectAll(".tick text")
      .style("fill", "#AD9DBC")
      .style("font-family", "Montserrat Variable")
      .style("font-size", "14px")
      .style("font-weight", "600");
  }

  createGrid() {
    this.svg
      .append("g")
      .attr("transform", `translate(0,${this.height - this.marginBottom})`)
      .call(d3.axisBottom(this.x).tickSizeOuter(0).tickFormat(d3.format(".0f")))
      .call((g) => g.select(".domain").remove())
      .call((g) => g.selectAll(".tick line").remove())
      .selectAll(".tick text")
      .style("fill", "#AD9DBC")
      .style("font-family", "Montserrat Variable")
      .style("font-size", "14px")
      .style("font-weight", "600");
  }

  createLabels() {
    this.svg
      .append("path")
      .attr("fill", "none")
      .attr("stroke", "#972A48")
      .attr("stroke-width", 6)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("d", this.line(this.dataValue));
  }

  drawLastData() {
    const lastDatum = this.dataValue[this.dataValue.length - 1];
    const svgImage = `
      <svg width="38" height="41" viewBox="0 0 38 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_2678_7411)">
          <circle cx="19" cy="19" r="10" fill="white"/>
          <circle cx="19" cy="19" r="6.5" stroke="#972A48" stroke-width="7"/>
        </g>
        <circle cx="19" cy="19" r="19" fill="#FDD2D2" fill-opacity="0.27"/>
        <defs>
          <filter id="filter0_d_2678_7411" x="3" y="9" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="6"/>
            <feGaussianBlur stdDeviation="3"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2678_7411"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2678_7411" result="shape"/>
          </filter>
        </defs>
      </svg>
    `;

    this.svg
      .append("foreignObject")
      .attr("width", 38)
      .attr("height", 41)
      .attr("x", this.x(lastDatum.date) - 19)
      .attr("y", this.y(lastDatum.quantity) - 20.5)
      .html(svgImage);
  }
}
