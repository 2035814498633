import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { history } from "instantsearch.js/es/lib/routers";
import {
  currentRefinements,
  infiniteHits,
  panel,
  searchBox,
} from "instantsearch.js/es/widgets";
import filtersConfig from "../filters_component/config";
import hitsConfig from "../hits_component/config";
import searchBoxConfig from "../box_component/config";
import refinementsConfig from "../refinements_component/config";
import { Turbo } from "@hotwired/turbo-rails";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["hits", "originalHits", "refinements", "searchBox"];
  static values = {
    apiKey: String,
    applicationId: String,
    filters: Array,
    index: String,
  };

  connect() {
    this.search = instantsearch(this.instantsearchConfig);
    this.connectWidgets();
    this.connectFilters();
    this.search.start();
    this.search.on("render", () => {
      this.hitsTarget.parentNode.classList.toggle(
        "c-admin--search--hits-searching",
        this.isSearching
      );
    });
  }

  disconnect() {
    if (this.search) this.search.dispose();
  }

  connectWidgets() {
    let widgets = [];

    if (this.hasRefinementsTarget) {
      widgets.push(
        currentRefinements({
          container: this.refinementsTarget,
          ...refinementsConfig,
        })
      );
    }

    if (this.hasHitsTarget) {
      widgets.push(
        infiniteHits({
          container: this.hitsTarget,
          ...hitsConfig,
        })
      );
    }

    if (this.hasSearchBoxTarget) {
      widgets.push(
        searchBox({
          container: this.searchBoxTarget,
          ...searchBoxConfig,
        })
      );
    }

    this.search.addWidgets(widgets);
  }

  connectFilters() {
    if (!this.hasFiltersElement || !this.hasFiltersValue) return;

    let filtersWidgets = [];
    this.filtersValue.forEach((filter) => {
      if (!filtersConfig[filter]) {
        console.error(`Filter not defined in config: ${filter}`);
        return;
      }

      const { widget, title, options } = filtersConfig[filter];
      const filterEl = document.createRange().createContextualFragment(`
        <div class="c-admin--search--filters-filter"></div>
      `);
      const container = filterEl.querySelector(
        ".c-admin--search--filters-filter"
      );
      this.filtersElement.appendChild(filterEl);
      options["container"] = container;

      const panelWidget = panel({
        hidden(options) {
          return options.canRefine === false;
        },
        cssClasses: {
          header: "c-admin--search--filters-title",
        },
        templates: {
          header(data, { html }) {
            return html`${title}`;
          },
        },
      })(widget);

      filtersWidgets.push(panelWidget(options));
    });

    this.search.addWidgets(filtersWidgets);
  }

  get searchClient() {
    return algoliasearch(this.applicationIdValue, this.apiKeyValue);
  }

  get instantsearchConfig() {
    return {
      indexName: this.indexValue,
      routing: {
        router: history({
          cleanUrlOnDispose: false,
          push: (url) => {
            window.history.pushState({}, null, url);
            Turbo.navigator.history.push(url);
          },
        }),
      },
      searchClient: this.searchClient,
      future: {
        preserveSharedStateOnUnmount: true,
      },
    };
  }

  get isSearching() {
    const state = this.search.renderState[this.indexValue];
    return (
      state.searchBox.query !== "" ||
      state.currentRefinements.items.length !== 0
    );
  }

  get filtersElement() {
    return document.querySelector(
      "[data-admin--search--algolia-target='filters']"
    );
  }

  get hasFiltersElement() {
    return this.filtersElement !== null;
  }
}
