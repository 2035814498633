import "./collapse_button_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  showContent(e) {
    e.preventDefault();
    this.element.classList.add("c-collapse-button-active");
  }
}
