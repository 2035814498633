export default {
  showLoadingIndicator: false,
  cssClasses: {
    list: "c-admin--list-root",
    item: "c-admin--list--item-root",
    loadMore:
      "c-admin--search--hits-more c-trek--button-root c-trek--button-size-narrow",
  },
  templates: {
    empty(results, { html }) {
      return html`<div class="c-admin--list-root">
        Aucun résultat pour <q>${results.query}</q>
      </div>`;
    },
    item(hit, { html, components }) {
      const packshot = hit.packshots?.horizontal?.medium;
      return html`
        <span class="c-admin--list--item-image">
          ${packshot
            ? html`<img src="${packshot}" loading="lazy" />`
            : html`<svg class="c-icon-root c-icon-trek--pic" role="img">
                <use xlink:href="#c-icon-trek--pic"></use>
              </svg>`}
        </span>

        <div class="c-admin--list--item-content">
          <div class="c-admin--list--item-title">
            <span
              class="c-admin--list--item-color-dot"
              style="--color: var(--list-dot-${hit.color_key}, var(--${hit.color_key}, ${hit.color_key}))"
            ></span>
            <a href="${hit.admin_url}"
              >${components.Highlight({ hit, attribute: "display_name" })}</a
            >
          </div>
        </div>

        <span class="c-admin--list--item-status">
          ${hit.complete
            ? html` <span
                class="c-pill-root"
                style="--pill-color: var(--limeade);"
                ><svg
                  class="c-icon-root c-icon-tick-square c-pill-icon"
                  role="img"
                >
                  <use xlink:href="#c-icon-tick-square"></use></svg
                ><span class="c-pill-content">Complète</span></span
              >`
            : html` <span
                class="c-pill-root"
                style="--pill-color: var(--cinnamon);"
                ><svg class="c-icon-root c-icon-danger c-pill-icon" role="img">
                  <use xlink:href="#c-icon-danger"></use></svg
                ><span class="c-pill-content">Incomplète</span></span
              >`}
        </span>
      `;
    },
    showMoreText(data, { html }) {
      return html`<span>Voir plus de résultats</span>`;
    },
  },
};
