import "./cart_line_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";
export class Controller extends BaseController {
  static targets = ["closeButton", "component"];

  close() {
    this.setupAnimation();
    this.hideAfterAnimation();
  }

  setupAnimation() {
    this.componentTarget.style.opacity = 0;
    this.componentTarget.style.transform = "translateY(-100%)";
  }

  hideAfterAnimation() {
    setTimeout(() => {
      this.componentTarget.style.display = "none";
    }, 400);
  }
}
