import "./carousel_component.css";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  connect() {
    this.createSwiper();
  }

  disconnect() {
    this.removeSwiper();
  }

  createSwiper() {
    if (!this.mobileMedia.matches) return;

    this.swiper = new Swiper(this.element, {
      slidesPerView: 1.3,
      spaceBetween: 16,
      breakpointsBase: "container",
      breakpoints: {
        576: {
          slidesPerView: 2.3,
        },
      },
    });
  }

  removeSwiper() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  }

  get mobileMedia() {
    return window.matchMedia("(max-width: 576px)");
  }
}
