import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "delegate"];
  static values = { delegateIdentifier: String };

  connect() {
    if (this.hasContent()) {
      this.submit();
    }
  }

  maybeDelegate(e) {
    if (this.hasDelegateTarget) {
      e.preventDefault();
      this.disable();
      this.submitDelegate(e);
      this.enable();
    }
  }

  submit() {
    this.buttonTarget.click();
  }

  refresh() {
    if (this.firstActiveFieldset) {
      this.firstActiveFieldset.scrollIntoView({ behavior: "smooth" });
      if (this.nextField) this.nextField.focus();
    }
  }

  submitDelegate(e) {
    if (!this.hasDelegateIdentifierValue) {
      console.error("Missing delegateIdentifier value");
      return;
    }

    const delegate = this.application.getControllerForElementAndIdentifier(
      this.delegateTarget,
      this.delegateIdentifierValue
    );
    delegate.submit(e);
  }

  enable() {
    this.buttonTarget.disabled = false;
  }

  disable() {
    this.buttonTarget.disabled = true;
  }

  hasContent() {
    return this.element.querySelector(
      "input:not([type=hidden]):not([type=submit]):not([type=button]):not([type=image]), textarea, select"
    ).value;
  }

  get firstActiveFieldset() {
    return document.querySelector(
      "fieldset:not(.c-form--fieldset-is-complete):not([inert]):not(:first-child)"
    );
  }

  get nextField() {
    return Array.from(
      this.firstActiveFieldset.querySelectorAll(
        "input:not([type=hidden]):not([type=submit]):not([type=button]):not([type=image]), textarea, select"
      )
    ).find((field) => !field.value);
  }
}
